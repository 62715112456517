import React, { useState, useEffect } from "react";

function App() {

  return (
    <div id="wrapper">
      <div id="logo" />
      <footer>
          <a href="https://www.instagram.com/thebeachresidence/" target="_blank" id="btn-instagram">@thebeachresidence</a>
          <a href="https://www.facebook.com/profile.php?id=100092425411890&sk=about" target="_blank" id="btn-facebook">@thebeachresidence</a>
          <a href="mailto:info@beachresidencesamui.com" target="_blank" id="btn-email">info@beachresidencesamui.com</a>
          <a href="tel:0066646463606" target="_blank" id="btn-phone">+66 64 646 3606</a>
      </footer>
    </div>
  );
}

export default App;
